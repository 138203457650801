@font-face {
    font-family: "cy-custom-icons";
    src: url("./cy-custom-icons.eot?16d68bdada0fe0a46bbe5f6c14f7e1ba?#iefix") format("embedded-opentype"),
url("./cy-custom-icons.woff2?16d68bdada0fe0a46bbe5f6c14f7e1ba") format("woff2"),
url("./cy-custom-icons.woff?16d68bdada0fe0a46bbe5f6c14f7e1ba") format("woff"),
url("./cy-custom-icons.ttf?16d68bdada0fe0a46bbe5f6c14f7e1ba") format("truetype"),
url("./cy-custom-icons.svg?16d68bdada0fe0a46bbe5f6c14f7e1ba#cy-custom-icons") format("svg");
}

i.icon.circle-check,
i.icon.circle-pause,
i.icon.circle-progress,
i.icon.cy,
i.icon.dolly-filled,
i.icon.exact,
i.icon.handyman,
i.icon.horizontal_split,
i.icon.jan_krediet,
i.icon.local_atm_white,
i.icon.move_to_inbox_white,
i.icon.navision,
i.icon.nutrition,
i.icon.outbox_white,
i.icon.postnl,
i.icon.script-type-light,
i.icon.square-info,
i.icon.swap_horizontal_circle_white,
i.icon.transmission,
i.icon.unit4,
i.icon.ups,
i.icon.vertical_split {
    font-family: cy-custom-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

i.icon.circle-check:before {
    content: "\f101";
}
i.icon.circle-pause:before {
    content: "\f102";
}
i.icon.circle-progress:before {
    content: "\f103";
}
i.icon.cy:before {
    content: "\f104";
}
i.icon.dolly-filled:before {
    content: "\f105";
}
i.icon.exact:before {
    content: "\f106";
}
i.icon.handyman:before {
    content: "\f107";
}
i.icon.horizontal_split:before {
    content: "\f108";
}
i.icon.jan_krediet:before {
    content: "\f109";
}
i.icon.local_atm_white:before {
    content: "\f10a";
}
i.icon.move_to_inbox_white:before {
    content: "\f10b";
}
i.icon.navision:before {
    content: "\f10c";
}
i.icon.nutrition:before {
    content: "\f10d";
}
i.icon.outbox_white:before {
    content: "\f10e";
}
i.icon.postnl:before {
    content: "\f10f";
}
i.icon.script-type-light:before {
    content: "\f110";
}
i.icon.square-info:before {
    content: "\f111";
}
i.icon.swap_horizontal_circle_white:before {
    content: "\f112";
}
i.icon.transmission:before {
    content: "\f113";
}
i.icon.unit4:before {
    content: "\f114";
}
i.icon.ups:before {
    content: "\f115";
}
i.icon.vertical_split:before {
    content: "\f116";
}
